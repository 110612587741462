import { ChakraProvider, ColorModeProvider } from "@chakra-ui/react";
import React from "react";
import theme from "../theme";
import initFirebaseAuth, {
  firbaseClientInitConfig,
} from "@lib/firebase/initFirebaseAuth";
import firebase from "firebase/app";
import Head from "next/head";
import {
  PutztGroupDataContextProvider,
  PutztPlanDataContextProvider,
  UserDataContextProvider,
} from "@lib/firebase/context";

initFirebaseAuth();

if (!firebase.apps.length) {
  firebase.initializeApp(firbaseClientInitConfig);
}

export default function App({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);
  return (
    <ChakraProvider resetCSS theme={theme}>
      <ColorModeProvider
        options={{
          useSystemColorMode: true,
        }}>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
        </Head>
        <UserDataContextProvider>
          <PutztGroupDataContextProvider>
            <PutztPlanDataContextProvider>
              {getLayout(<Component {...pageProps}></Component>)}
            </PutztPlanDataContextProvider>
          </PutztGroupDataContextProvider>
        </UserDataContextProvider>
      </ColorModeProvider>
    </ChakraProvider>
  );
}
