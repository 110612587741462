import React, { useState, useEffect } from "react";
import { getFirebaseCollection } from "../firebase";
import { useToast } from "@chakra-ui/react";

export const usePutztGroupDocument = ({ putztID, noFetching = false } = {}) => {
  const toast = useToast();
  const [clientPutztGroupData, setClientPutztGroupData] = useState(null);
  const [loadingPutztGroupData, setLoadingPutztGroupData] = useState(true);

  const putztCollectionFromUser = (documentID) => {
    return getFirebaseCollection("putzt-app").doc(documentID);
  };

  const fetchPutztGroupData = (documentID) => {
    if (!documentID) {
      setLoadingPutztGroupData(true);
      return null;
    }
    putztCollectionFromUser(documentID).onSnapshot((snapshot) => {
      setClientPutztGroupData({
        ...snapshot.data(),
        ...clientPutztGroupData,
      });
      setLoadingPutztGroupData(false);
    });
  };

  const updatePutztGroupData = (updatecontent = {}, putztid = putztID) => {
    putztCollectionFromUser(putztid)
      .update(updatecontent)
      .then(() => {})
      .catch((error) => {
        console.log(error);
        toast({
          title: "Hier gibts ein Problem!",
          description:
            "Deine Änderungen in den Putzgruppeneinstellungen wurden nicht gespeichert. Weiß aber auch nicht warum.",
          status: "error",
          duration: 2500,
          isClosable: true,
          position: "top",
        });
      });
  };

  useEffect(() => {
    if (noFetching) {
      return;
    }
    const unsubscribePutztGroupData = fetchPutztGroupData(putztID);

    return () => unsubscribePutztGroupData;
  }, [putztID]);

  return {
    putztGroup: {
      data: clientPutztGroupData,
      isLoading: loadingPutztGroupData,
    },
    updatePutztGroupData,
  };
};

export const usePutzplanDocument = ({
  putztID,
  userID,
  noFetching = false,
} = {}) => {
  const toast = useToast();
  const [clientPutzplandata, setClientPutzplandata] = useState(null);
  const [onlyUserPutzplan, setOnlyUserPutzplan] = useState(null);
  const [loadingPutzplanData, setLoadingPutzplanData] = useState(true);

  const putztCollectionFromUser = (documentID) => {
    return getFirebaseCollection("putzt-app").doc(documentID);
  };

  const fetchPutzplanData = (documentID) => {
    if (!documentID) {
      setLoadingPutzplanData(true);
      return null;
    }

    putztCollectionFromUser(documentID)
      .collection("putzplan")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        let dbdata = [];
        snapshot.forEach((doc) => {
          dbdata.push({ userid: doc.id, content: doc.data() });
        });
        setClientPutzplandata(dbdata);
        setLoadingPutzplanData(false);
      });
  };

  const getOnlyUserPutzplandata = (userID, putzplandata) => {
    if (!userID || !putzplandata) {
      setOnlyUserPutzplan(null);
      return null;
    }
    const userPutzplan = putzplandata.find((user) => user.userid === userID);
    setOnlyUserPutzplan(userPutzplan?.content);
  };

  const updateSinglePutzplanData = (
    updatecontent = {},
    userid = userID,
    putztid = putztID,
  ) => {
    return putztCollectionFromUser(putztid)
      .collection("putzplan")
      .doc(userid)
      .update(updatecontent);
  };

  useEffect(() => {
    if (noFetching) {
      return;
    }

    const unsubscribePutztplanData = fetchPutzplanData(putztID);

    return () => unsubscribePutztplanData;
  }, [putztID]);

  useEffect(() => {
    if (!putztID || noFetching) {
      return;
    }
    const unsubscribe = getOnlyUserPutzplandata(userID, clientPutzplandata);
    return () => unsubscribe;
  }, [clientPutzplandata]);

  return {
    putzplan: {
      data: clientPutzplandata,
      onlyUserData: onlyUserPutzplan,
      isLoading: loadingPutzplanData,
    },
    updateSinglePutzplanData,
  };
};

/**
 * @returns promise
 */
export const createNewPutztGroup = (user) => {
  if (!user.data?.docID) {
    return;
  }

  return getFirebaseCollection("putzt-app")
    .add({
      weeknumber: 0,
      lastUpdate: null,
      availableRooms: [],
      firstDrawDone: false,
      groupName: "",
      initSetupDone: false,
    })
    .then((docRef) => {
      getFirebaseCollection("users")
        .doc(user.data.docID)
        .update({ putztID: docRef.id });
      return getFirebaseCollection("putzt-app")
        .doc(docRef.id)
        .collection("putzplan")
        .doc(user.data.docID)
        .set({
          name: user.data.name,
          isGroupAdmin: true,
          status: "active",
        });
    })
    .catch(console.error);
};
