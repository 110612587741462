import react, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { usePutztGroupDocument, usePutzplanDocument } from "./hooks/putztdata";
import { useUserDocument } from "./hooks/userdata";

const UserDataContext = createContext();
const PutztGroupDataContext = createContext();
const PutzplanDataContext = createContext();

const UserDataContextProvider = ({ children }) => {
  const { user } = useUserDocument();

  return (
    <UserDataContext.Provider value={user}>{children}</UserDataContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error(
      "useUserContext must be used within a UserDataContextProvider",
    );
  }
  return context;
};

const PutztGroupDataContextProvider = ({ children }) => {
  const user = useContext(UserDataContext);
  const { putztGroup } = usePutztGroupDocument({
    putztID: user?.data?.putztID,
  });

  return (
    <PutztGroupDataContext.Provider value={putztGroup}>
      {children}
    </PutztGroupDataContext.Provider>
  );
};

const usePutztGroupContext = () => {
  const context = useContext(PutztGroupDataContext);

  if (context === undefined) {
    throw new Error(
      "usePutztGroupContext must be used within a PutztGroupDataContextProvider",
    );
  }
  return context;
};

const PutztPlanDataContextProvider = ({ children }) => {
  const user = useContext(UserDataContext);
  const { putzplan } = usePutzplanDocument({
    putztID: user?.data?.putztID,
    userID: user?.data?.docID,
  });

  return (
    <PutzplanDataContext.Provider value={putzplan}>
      {children}
    </PutzplanDataContext.Provider>
  );
};

const usePutzplanContext = () => {
  const context = useContext(PutzplanDataContext);

  if (context === undefined) {
    throw new Error(
      "usePutzplanContext must be used within a PutzplanDataContext",
    );
  }
  return context;
};

export {
  UserDataContextProvider,
  useUserContext,
  PutztGroupDataContextProvider,
  usePutztGroupContext,
  PutztPlanDataContextProvider,
  usePutzplanContext,
};
