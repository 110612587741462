import react, { useState, useEffect } from "react";
import firebase, { getFirebaseCollection } from "@lib/firebase/firebase";
import { useToast } from "@chakra-ui/react";

export const useUserDocument = ({ noFetching = false } = {}) => {
  const toast = useToast();
  const [userId, setUserId] = useState(null);
  const [userdata, setUserdata] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchClientSideUserData = () => {
    if (userId) {
      getFirebaseCollection("users")
        .doc(userId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setUserdata({ docID: userId, ...snapshot.data() });
            setLoading(false);
          } else {
            createProfileOnAuth(firebase.auth().currentUser);
          }
        });
    }
  };

  useEffect(() => {
    if (noFetching) {
      return;
    }

    const unsubscribe = fetchClientSideUserData();
    return unsubscribe;
  }, [userId]);

  const updateUserData = (updatecontent = {}) => {
    getFirebaseCollection("users")
      .doc(userId)
      ?.update(updatecontent)
      .then(() => {
        toast({
          title: "Speichern erfolgreich",
          description: "Deine Änderungen wurden gespeichert",
          status: "success",
          duration: 2500,
          isClosable: true,
          position: "top",
        });
      })
      .catch((error) => {
        toast({
          title: "Hier gibts ein Problem!",
          description:
            "Deine Änderungen wurden gespeichert. Weiß aber auch nicht warum.",
          status: "error",
          duration: 2500,
          isClosable: true,
          position: "top",
        });
      });
  };

  const updateFirebaseUserProfile = (updatecontent = {}) => {
    const user = firebase.auth().currentUser;
    user.updateProfile(updatecontent);
  };

  const updateFirebaseUserEmail = (newEmail) => {
    const user = firebase.auth().currentUser;
    user.updateEmail(newEmail).catch((error) => {
      console.error(error);
      toast({
        title: "Bitte melde dich erneut an!",
        description:
          "Bevor du deine E-Mail ändern kannst, musst du dich erneut anmelden.",
        status: "error",
        duration: 2500,
        position: "top",
      });
      firebase.auth().signOut();
    });
  };

  return {
    user: {
      data: userdata,
      isLoading: loading,
    },
    updateUserData,
    updateFirebaseUserProfile,
    updateFirebaseUserEmail,
  };
};

export const createProfileOnAuth = (user) => {
  if (!user) {
    return;
  }

  const { email, phoneNumber, uid, displayName } = user || {};
  return getFirebaseCollection("users")
    .doc(uid)
    .set({
      email: email,
      phoneNumber: phoneNumber,
      hasSignedInOnce: true,
      name: displayName,
      putztID: "",
    })
    .catch(console.error);
};
